import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const apropos = () => (
  <Layout>
    <SEO title="Fabien Naté - À Propos" />
    <h3 className='underline-title'>À Propos</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p className="properly-aligned">
      Ce site web est créé pour célébrer la vie de Fabien Naté en tant que poète et auteur ainsi que ses oeuvres littéraires.
      </p>
      <p className="properly-aligned">
      Le site est conçu par les proches de Fabien Naté, ses enfants, ses petits-enfants et ses arrières petits-enfants qui ont voulu
      partager ses oeuvres, les rendre plus accessibles et continuer à les immortaliser pour la postérité.
      </p>
      <p className="properly-aligned">
      Animés par la même passion d'écriture, nous espérons que beaucoup apprécieront ses poèmes et s'en inspireront.
      </p>
      <p className="properly-aligned">
      Nous disons merci aux talents de nos amis de par le monde, à tous ceux-là qui ont rendu et continuent à rendre possible ce project.
      </p> 
      <br />  
      <p className="font-italic">Charly Soza</p>  
    </div>
    <br />
    <br />
    <Link to="/">Biographie</Link>
  </Layout>
)

export default apropos
